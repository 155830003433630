import {graphql} from 'gatsby'
import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import Layout from '../../components/layout'

const allTagsPage = ({
  data: { allMarkdownRemark: { group }, site: { siteMetadata: { title } } },
}) => (
  <Layout>
  <article className="article">
    <Helmet title={`Tags | ${title}`} />
    <header>
      <h1 className="title is-size-2 is-bold-light">Tags</h1>
    </header>
    <ul className="taglist">
      {group.map(tag => (
        <li key={tag.fieldValue}>
          <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
            {tag.fieldValue} ({tag.totalCount})
          </Link>
        </li>
      ))}
    </ul>
  </article>
  </Layout>

)

export default allTagsPage

export const allTagsPageQuery = graphql`
  query allTagsPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000,
      filter: {frontmatter: {tags: {ne:"Case Study"}}}){
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
